import type { CustomFlowbiteTheme } from 'flowbite-react'

export const customNavbarTheme: CustomFlowbiteTheme['navbar'] = {
  root: {
    base: 'bg-white px-4 py-4 dark:border-gray-700 dark:bg-gray-800',
    rounded: {
      on: 'rounded',
      off: ''
    },
    bordered: {
      on: 'border',
      off: ''
    },
    inner: {
      base: 'mx-auto flex flex-wrap items-center justify-between',
      fluid: {
        on: '',
        off: 'container'
      }
    }
  },
  collapse: {
    base: 'w-full md:block md:w-auto',
    list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium',
    hidden: {
      on: 'hidden',
      off: ''
    }
  }
}
