import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

export * from './AlertErrorsContext'
export { default as AlertContextProvider } from './AlertContextProvider'
export { default as RoutingErrorsHandler } from './RoutingErrorsHandler'
export { default as Error500Page } from './Error500Page'
export { default as Error404Page } from './Error404Page'

const environment =
  import.meta.env.VITE_SENTRY_ENVIRONMENT ??
  (import.meta.env.MODE === 'production' ? 'production' : 'development')

const sentryConfig = {
  dsn: 'https://9c1641f23aad9bba36c4e250f3103a5d@o4506598293504000.ingest.us.sentry.io/4508201135046656',
  environment,
  replaysSessionSampleRate: 1,
  beforeSend(event: Sentry.ErrorEvent) {
    if (event.environment === 'development') {
      // Trace development errors locally without sending them to Sentry dashboard
      console.log(
        '%cError captured:\n',
        'color:red;font-size:2rem;font-weight:bold',
        event
      )
      return null
    }
    return event
  },
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ]
}

Sentry.init(sentryConfig)

export default Sentry
