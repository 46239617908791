import { Route, Navigate } from 'react-router-dom'
import { RoutingErrorsHandler } from './alert'

export default function configureRoutes() {
  return (
    <Route path="/" errorElement={<RoutingErrorsHandler />}>
      <Route path="login" lazy={() => import('./auth/Login')} />
      <Route path="register" lazy={() => import('./auth/Register')} />
      <Route path="logout" lazy={() => import('./auth/Logout')} />
      <Route id="root" path="/" lazy={() => import('./App')}>
        <Route
          path="workflows/:nodeId?/:objectId?"
          lazy={() => import('./workflows')}
        />
        <Route path="triggers" lazy={() => import('./triggers')} />
        <Route path="segments" lazy={() => import('./segments')} />
        <Route path="audiences" lazy={() => import('./audiences')}>
          <Route index element={<Navigate to="/audiences/custom" replace />} />
          <Route path="custom" lazy={() => import('./audiences/custom')} />
          <Route
            path="profiles/:segmentId?"
            lazy={() => import('./audiences/profiles')}
          />
        </Route>
        <Route path="channels" lazy={() => import('./channels')}>
          <Route path="web-blocks" lazy={() => import('./channels/webblocks')}>
            <Route
              path="web-spaces/:id?"
              lazy={() => import('./channels/webblocks/web-spaces')}
            />
            <Route
              path="web-block-designs/:id?"
              lazy={() => import('./channels/webblocks/web-blocks')}
            />
          </Route>
        </Route>

        <Route path="campaigns" lazy={() => import('./campaigns')} />
        <Route
          path="campaigns/:campaignId"
          lazy={() => import('./campaigns/edit')}
        >
          <Route
            path=":sequenceId?"
            lazy={() => import('./campaigns/sequence')}
          />
        </Route>
        <Route
          path="peel-create-audience"
          lazy={() => import('./peel-create-audience')}
        />
        <Route path="profile" lazy={() => import('./profile')} />
        <Route path="settings" lazy={() => import('./settings')}>
          <Route index lazy={() => import('./settings/trackers')} />
          <Route path="brand-kit" lazy={() => import('./settings/brand-kit')} />
        </Route>
      </Route>
    </Route>
  )
}
