import classNames from 'classnames'
import { customButtonTheme } from '../theme/components/button'
import merge from 'lodash.merge'
import { Button, ButtonProps } from 'flowbite-react/components/Button'
import { forwardRef, Ref } from 'react'

const RelayButton = forwardRef(
  (props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
    const className = classNames('whitespace-nowrap', props.className)
    return (
      <Button
        ref={ref}
        {...merge(
          { ...props },
          { theme: customButtonTheme },
          {
            size: props.size ?? 'xs',
            color: props.color ?? 'secondary',
            className
          }
        )}
      />
    )
  }
)

export default RelayButton
